import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ConfiguratorService} from '../../services/configurator.service';
import {fadeInDownOnEnterAnimation, fadeOutOnLeaveAnimation} from 'angular-animations';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-variants',
    templateUrl: './variants.component.html',
    styleUrls: ['./variants.component.scss'],
    animations: [fadeInDownOnEnterAnimation(), fadeOutOnLeaveAnimation()]
})
export class VariantsComponent implements OnInit, OnDestroy {
    @Output() variantEmit = new EventEmitter();
    _variants: any = null;
    lang = '';
    subscribtions: Subscription = new Subscription();
    btnSubmitted = false;
    form: FormGroup = null;
    scratch = new FormGroup({
        pressure: new FormControl('', [Validators.required, Validators.pattern('[0-9]{3}')]),
        flow: new FormControl('', [Validators.required, Validators.pattern('[0-9]{2}.[0-9]')]),
    });

    validatorPatterns = {
        pressure: '[0-9]{3}',
        flow: '[0-9]{2}\.[0-9]',
    };

    validatorErrors = {
        pressure: 'VARIANTS.ERROR.PRESSURE',
        flow: 'VARIANTS.ERROR.FLOW',
    };

    constructor(public confService: ConfiguratorService,
                private translate: TranslateService) {

        this.lang = translate.currentLang;
        this.subscribtions.add(
            translate.onLangChange.subscribe((data) => {
                this.lang = data.lang;
            })
        );
    }

    @Input('variants') set getVariants(value) {
        if (!!value) {
            this._variants = value.variants;
            this._variants.map(variant => {
                if (variant.params && variant.params.length > 0) {
                    variant.params.map(item => {
                        item.showParams = false;
                    });
                }
            });
        }
    }

    ngOnInit() {
    }

    selectVariant(variant) {
        if (!variant.showParams && variant.params && variant.params.length) {

            // обнуляем форму
            this.form = new FormGroup({});

            let pattern = [Validators.required];
            variant.params.map(param => {

                // все параметры обязательны
                pattern = [Validators.required];

                // создаём валидатор по маске
                if (this.validatorPatterns.hasOwnProperty(param.mask)) {
                    pattern.push(
                        Validators.pattern(this.validatorPatterns[param.mask])
                    );
                } else {
                    console.log('Отсутствует валидатор для:', param.mask);
                }


                this.form.addControl(
                    param.mask,
                    new FormControl('', pattern)
                );

            });

            variant.params.map(param => {
                this.form.patchValue({[param.mask]: param.value});
            });

            this._variants.map(item => {
                item.showParams = false;
            });

            variant.showParams = true;
            return true;
        }

        this._variants.map(item => {
            item.showParams = false;
        })

        this.variantEmit.emit(variant);
        this.confService.nextStep.next();
    }

    ngOnDestroy() {
        this.subscribtions.unsubscribe();
    }

    dataSubmit(variant) {
        this.btnSubmitted = true;
        if (this.form.status === 'VALID') {
            if (variant.params && variant.params.length) {
                variant.params.map(param => {
                    param.value = this.form.value[param.mask];
                });
            }
            this.btnSubmitted = false;
            this.selectVariant(variant);
        }
    }
}
